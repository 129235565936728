import React, {useEffect} from 'react';
import './Services.css';
import {motion} from 'framer-motion'
import {FaReact, FaAws, FaSwift} from 'react-icons/fa';
import {IoLogoPython} from 'react-icons/io'
import {SiMysql, SiPhp, SiKotlin} from 'react-icons/si'
import androidImage from '../../../assets/android.jpg'
import appleImage from '../../../assets/apple.jpg'
import wordpressImage from '../../../assets/wordpress.jpg'
import websiteImage from '../../../assets/website.jpg'
import reactImage from '../../../assets/reactNative.jpg'

function Services() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {duration: 1}}}>
      <div className="servicesHeader">
        What do we do?
      </div>
      <div className="servicesDesc">
        Welborn Technology LLC specializes in imagining, designing, and refining dynamic Mobile and Web applications.
      </div>
      <div className="iconContainer">
        <FaReact className="servicesIcons" />
        <IoLogoPython className="servicesIcons" />
        <FaAws className="servicesIcons" />
        <SiMysql className="servicesIcons" />
        <SiPhp className="servicesIcons" />
        <FaSwift className="servicesIcons" />
        <SiKotlin className="servicesIcons" />
      </div>
      <div className="techContainer">
        <div className="reactNativeContainer">
          <div className="techName">REACT NATIVE</div>
          <img src={reactImage} className="techImage" alt="" />
          <div className="techBody">
            {/* Developing two of the same apps for two different platforms takes twice the time. React Native allows us to develop one app for both the Apple App Store and the Google Play Store. We can build faster and allow you to start utilizing your app sooner. */}
          </div>
        </div>
        <div className="reactNativeContainer">
          <div className="techName">IOS</div>
          <img src={appleImage} className="techImage" alt="" />
          <div className="techBody">
            {/* Developing two of the same apps for two different platforms takes twice the time. React Native allows us to develop one app for both the Apple App Store and the Google Play Store. We can build faster and allow you to start utilizing your app sooner. */}
          </div>
        </div>
        <div className="reactNativeContainer">
          <div className="techName">ANDROID</div>
          <img src={androidImage} className="techImage" alt="" />
          <div className="techBody">
            
          </div>
        </div>
        <div className="reactNativeContainer">
          <div className="techName">WEB</div>
          <img src={websiteImage} className="techImage" alt="" />
          <div className="techBody">
            {/* Developing two of the same apps for two different platforms takes twice the time. React Native allows us to develop one app for both the Apple App Store and the Google Play Store. We can build faster and allow you to start utilizing your app sooner. */}
          </div>
        </div>
        <div className="reactNativeContainer">
          <div className="techName">WORDPRESS</div>
          <img src={wordpressImage} className="techImage" alt="" />
          <div className="techBody">
            
          </div>
        </div>
      </div>
      {/* <div className="servicesSectionHeader">
        MOBILE DEVELOPMENT
      </div>
      <div className="servicesDesc">
        Your customers have phones in their pockets, your app should be on it! In today's market, mobile applications are game changing tools to help and keep your customers. We develop software that utilizes the cloud to deliver scalable and reliable experiences for your users. 
      </div>
      <div className="servicesSectionHeader">
        WEB DEVELOPMENT
      </div>
      <div className="servicesDesc">
        Customers need to be able to find your business, clients find services via search engines. Web Applications show potential customers who you are and what you offer. Also, they are the perfect companion to your Mobile App providing static or dynamic information about your business.
      </div> */}
    </motion.div>
  );
}

export default Services;
