import React, {useEffect} from 'react';
import './Home.css';
import Imoto from './featured/Imoto';
import AppDev from '../appdevelopment/AppDev'
import {FaPhoneAlt} from 'react-icons/fa';
import {motion} from 'framer-motion'
import {Link} from 'react-router-dom';

const transition = { duration: 1.4, ease: [0.6, 0.05, 0.1, 0.9] }

function Home() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    // exit={{opacity: 0}}
    <motion.div className="homeContainer">
        <div className="homeFlex">
          <div className="homeLeft">
            <motion.div className="attention" initial={{opacity: 0, x: 200}} animate={{opacity: 1, x: 0, transition: {delay: 0, ...transition}}}>
              We Build Your Vision
            </motion.div>
            <motion.div className="message" initial={{opacity: 0, y: 20}} animate={{opacity: 1, y: 0, transition: {delay: 0.3, ...transition}}}>
              Quality Mobile and Web Applications to help your business grow.
            </motion.div>
            <AppDev />
          </div>
          <div className="homeRight">
            <div className="featured">
              FEATURED WORK
            </div>
            <Imoto />
          </div>
        </div>
        <div className="attentions">
          Have an idea?
        </div>
        <div className="idea">
          We love to hear about your projects! Free consultation to discuss your business needs.
        </div>
        <div>
          <Link to="/contact"><button className="contactButtonHome">CONTACT US<FaPhoneAlt className="phoneIcon" /></button></Link>
        </div>
    </motion.div>
  );
}

export default Home;
