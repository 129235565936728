import './AppDev.css?v=6';
import {FaReact, FaAws, FaSwift} from 'react-icons/fa';
import {IoLogoPython} from 'react-icons/io'
import {SiMysql, SiPhp, SiKotlin} from 'react-icons/si'
import {motion} from 'framer-motion'

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

function AppDev() {

  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {delay: 0.5, ...transition}}}>
        <div className="appDevContainer">
            <div className="appDevLearnDiv">
                <div style={{color: '#FFF', letterSpacing: 10}}>TECHNOLOGIES</div>
                <FaReact className="appDevIcons" />
                <IoLogoPython className="appDevIcons" />
                <FaAws className="appDevIcons" />
                <SiMysql className="appDevIcons" />
                <SiPhp className="appDevIcons" />
                <FaSwift className="appDevIcons" />
                <SiKotlin className="appDevIcons" />
            </div>
        </div>
    </motion.div>
  );
}

export default AppDev;
