import React from "react";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './App.css';
import Nav from './mobile/components/nav/Nav'
import Footer from './mobile/components/footer/Footer'
import Home from './mobile/components/home/Home'
import Work from './mobile/components/work/Work'
import {AnimatePresence} from 'framer-motion'
import Services from './mobile/components/services/Services'
import Contact from './mobile/components/contact/Contact'

function App() {
  return (
    <Router>
      <div className="theAppContainer">
      <Nav />
        <Route render={({ location }) => (
          <AnimatePresence initial={true} exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/work">
                <Work />
              </Route>
              <Route path="/services">
                <Services />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
            </Switch>
          </AnimatePresence>
        )} />
      <Footer />
      </div>
    </Router>
  );
}

export default App;
