import './Nav.css';
import {Link} from 'react-router-dom'
import menu from '../../../assets/menu.png'
import {useState} from 'react'
import {motion} from 'framer-motion'


function Nav() {

  const [theNav, setTheNav] = useState(false)
  const transition = { duration: 1, ease: [0.6, 0.05, 0.1, 0.9] }

  return (
    <div className="container">
        {theNav && <motion.div className='theModal' initial={{opacity: 0, x: 200}} animate={{opacity: 1, x: 0, transition: {delay: 0, ...transition}}}>
            <Link to="/" onClick={() => {
              setTimeout(() => {setTheNav(!theNav)}, 250);
            }}>
              <li className="footerList"><span className="navlinks">HOME</span></li>
            </Link>
            <Link to="/services" onClick={() => {
              setTimeout(() => {setTheNav(!theNav)}, 250);
            }}>
              <li className="footerList"><span className="navlinks">SERVICES</span></li>
            </Link>
            <Link to="/work" onClick={() => {
              setTimeout(() => {setTheNav(!theNav)}, 250);
            }}>
              <li className="footerList"><span className="navlinks">PORTFOLIO</span></li>
            </Link>
            <Link to="/contact" onClick={() => {
              setTimeout(() => {setTheNav(!theNav)}, 250);
            }}>
              <li className="footerList"><span className="navlinks">CONTACT</span></li>
            </Link>
        </motion.div>}
        <Link to="/">
          <div className="logoDiv">
            <div className="logo">WELBORN</div>
            <div className="logo">TECHNOLOGY</div>
          </div>
        </Link>

          <Link to="/">
              <li className="footerLi"><span className="links">HOME</span></li>
            </Link>
            <Link to="/services">
              <li className="footerLi"><span className="links">SERVICES</span></li>
            </Link>
            <Link to="/work">
              <li className="footerLi"><span className="links">PORTFOLIO</span></li>
            </Link>
            <Link to="/contact">
              <li className="footerLi"><span className="links">CONTACT</span></li>
            </Link>
      <img src={menu} className="menuButton" alt="Menu" onClick={() => setTheNav(!theNav)}/>
    </div>
  );
}

export default Nav;
