import './Imoto.css';
import iMotoSplash from '../../../../assets/imotosplash.jpg';
import {BiChevronRightCircle} from 'react-icons/bi';
import {motion} from 'framer-motion'
import {Link} from 'react-router-dom';

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

function Imoto() {

  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {delay: 0.5, ...transition}}}>
        <div className="iMotoContainer">
            <div className="iMotoLogo">
                iMoto
            </div>
            <div className="imotoFlexDiv">
                <div className="imageTextDiv">
                    <div>
                        <img src={iMotoSplash} className="imotosplash" alt="" />
                    </div>
                    <div className="imotodesc">
                        Riders don’t have to struggle finding out where they can go ride this weekend, iMoto gives riders a friendly environment for all things moto.
                    </div>
                </div>
                {/* <div className="imotoRightDiv">
                    <div>
                    <GrAppleAppStore className="storeIcons" />
                    </div>
                    <div>
                    <FaGooglePlay className="storeIcons" />
                    </div>
                </div> */}
            </div>
            <div className="imotoLearnDiv">
                <Link to="/work"><button className="imotoButton">
                    <div className="imotoButtonDiv">
                        {/* <div className="learnDiv"><a href="https://imotoracing.com" target="_blank" rel="noreferrer">LEARN MORE</a></div> */}
                        <div className="learnDiv">LEARN MORE</div>
                        <div className="learnPNGDiv"><BiChevronRightCircle className="rightCircle" /></div>
                    </div>
                </button>
                </Link>
            </div>
        </div>
    </motion.div>
  );
}

export default Imoto;
