import React, {useEffect} from 'react';
import './Work.css';
import {motion} from 'framer-motion'
import imotoVideo from '../../../assets/iMoto.mov'
import quikVideo from '../../../assets/QuikVentory.mp4'
import imotoPlaceholder from '../../../assets/imotoPlaceholder.jpg'
import quikPlaceholder from '../../../assets/quikventoryPlaceholder.jpg'
import {FaReact, FaAws} from 'react-icons/fa';
import {IoLogoPython, IoLogoAndroid} from 'react-icons/io'
import {SiMysql, SiPhp, SiKotlin} from 'react-icons/si'

function Work() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {duration: 1}}}>
        <div className="workHeader">
          Portfolio
        </div>
        <div className="workDesc">A few projects we have worked on.</div>
        <div className="workContentContainer">
          <div className="workImotoContainer">
            <div className="workImotoLeft">
              <video className="iMotoVideo" controls poster={imotoPlaceholder} src={imotoVideo} />
            </div>
            <div className="workImotoRight">
                <div className="workImotoHeader">
                  iMoto
                </div>
              <div>
                <FaReact className="workIcons" />
                <IoLogoPython className="workIcons" />
                <FaAws className="workIcons" />
                <SiMysql className="workIcons" />
              </div>
              <div>
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}
              <p className="workParagraph">iMoto is a Social Media Platform designed with the Motocross Community in mind. Given the ever changing circumstances with race tracks across the country, racers and riders alike need to be able to keep up with track owners and promoters. iMoto implements an easy to use interface allowing racers to see up-to-date information with one click after launching the app.</p>
              <p className="workParagraph">React Native was used to code the front end, leading to an Apple and Android version with one code base. Amazon Web Services makes up the back end infastructure, giving a scalable and reliable service for iMoto users across the country.</p>
              </div>
            </div>
          </div>
          <div className="workQuikventoryContainer">
            <div className="workQuikventoryLeft">
              <div className="workQuikHeader">
                QuikVentory
              </div>
              <div style={{margin: 5}}>
                <IoLogoAndroid className="workIcons" />
                <SiKotlin className="workIcons" />
                <SiPhp className="workIcons" />
                <SiMysql className="workIcons" />
              </div>
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}
              <p className="workParagraph">QuikVentory was born at the University of Southern Mississippi as the capstone project for my Senior year as a Computer Science major. I was the Lead Developer and taught my peers how to split the front end and back end technologies, then bridge the two with HTTPS requests.</p>
              <p className="workParagraph">The front end was developed in Android Studio utilizing the Kotlin programming language. The back end was written in PHP on a linux server running Apache. The app allowed users to create accounts for their business and login from any Android device. Users could then view, update, and delete their current inventory.</p>
            </div>
            <div className="workQuikventoryRight">
              <video src={quikVideo} className="quikVideo" controls poster={quikPlaceholder} />
            </div>
          </div>
        </div>
    </motion.div>
  );
}

export default Work;
