import React, {useEffect, useState} from 'react';
import './Contact.css';
import {motion} from 'framer-motion'
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa';

function Contact() {

  const [displayNumber, setDisplayNumber] = useState('');
  const [nameValid, setNameValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [newNumber, setNewNumber] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleNameInput = (e) => {
    setName(e.target.value);

    var test = /^[a-zA-Z0-9 .]{1,30}$/;
    if(e.target.value.match(test)){
        setNameValid(true);
    } else {
        setNameValid(false);
    }
  };

  const handleMessageInput = (e) => {
    setMessage(e.target.value);

    var test = /^[a-zA-Z0-9 .,'?!]{1,250}$/;
    if(e.target.value.match(test)){
        setMessageValid(true);
    } else {
        setMessageValid(false);
    }
  };

  const handlePhoneNumberInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setDisplayNumber(formattedPhoneNumber);
    if(formattedPhoneNumber.length === 12){
        setPhoneNumberValid(true);
        setNewNumber(formattedPhoneNumber.replace(/-/g,''))
    } else {
        setPhoneNumberValid(false);
    }
  }

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`;
  }

  const handleSubmit = () => {
    setError(false)
    setSuccess(false)
    if (!phoneNumberValid || !nameValid || !messageValid) {
      setError(true)
      return;
    }
    const vals = {name: name, number: newNumber, message: message}
      fetch('https://b7jqkoiwij.execute-api.us-east-2.amazonaws.com/prod/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': 'goD3gonRxc57vtdz1PmDq1lyHfqPjtR27otLKzPF'
        },
        body: JSON.stringify(vals)
      })
      .then((res) => {
        if (res.ok){
          return res.json()
        }else {
          throw Error('Oops in contact')
        }
      })
      .then((json) => {
        if(json === -1){
            setError(true)
        } else if(json === 0){
            setSuccess(true)
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {duration: 1}}}>
      <div className="contactHeader">
        Have a Question?
      </div>
      <div className="contactSubHeader">
        We love to hear about your projects!
      </div>
      <div className="contactBodyContainer">
        <div className="contactBodyLeft">
            <div className="contactInput">
              <input placeholder="Name" value={name} onChange={handleNameInput} />
              {nameValid ? <FaCheckCircle className='icons' /> : <FaTimesCircle className='noIcons' />}
            </div>
            <div className="contactInput">
              <input placeholder="Number" onChange={handlePhoneNumberInput} value={displayNumber} />
              {phoneNumberValid ? <FaCheckCircle className='icons' /> : <FaTimesCircle className='noIcons' />}
            </div>
            <div className="contactInput">
              <textarea placeholder="Say Hey!" value={message} onChange={handleMessageInput} />
              {messageValid ? <FaCheckCircle className='iconss' /> : <FaTimesCircle className='noIconss' />}
            </div>
            {success && <div><span style={{color: 'green'}}>SUCCESS</span></div>}
            {error && <div><span style={{color: 'red'}}>ERROR</span></div>}
            <div style={{margin: 20}}>
              <button className="contactPageButton" onClick={handleSubmit}>SEND</button>
            </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Contact;
