import './Footer.css';
import {Link} from 'react-router-dom';

function Footer() {
  return (
    <div className="footerContainer">
      <div className="footerLeftRightDiv">
        <div className="footerLeftDiv">
          <ul>
          <Link to="/">
              <li className="footerList">HOME</li>
            </Link>
            <Link to="/services">
              <li className="footerList">SERVICES</li>
            </Link>
            <Link to="/work">
              <li className="footerList">PORTFOLIO</li>
            </Link>
            <Link to="/contact">
              <li className="footerList">CONTACT</li>
            </Link>
          </ul>
        </div>
        {/* <div className="footerRightDiv">
          <div>
            <HiOutlineMail className="footerEmail" />
          </div>
          <div>
            <RiPhoneLine className="footerPhone" />
          </div>
        </div> */}
      </div>
      <div className="footerCopyright">&#169; 2022 Welborn Technology LLC</div>
    </div>
  );
}

export default Footer;
